<template>
  <el-config-provider :locale="locale">
    <PageLayout />

    <Chat />
  </el-config-provider>
  <!-- <Robot /> -->
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import PageLayout from '@/components/Layout/index.vue'
import locale from 'element-plus/es/locale/lang/zh-cn'
// import Robot from '@/components/Robot/index.vue'
import { useWindowSize, useElementSize } from '@vueuse/core'
import usePrefetch, { PrefetchItem } from './hooks/state/use-prefetch'
import { App } from '@/config'
import Chat from '@/components/Chat/view.vue'

// hooks
const { height } = useWindowSize()
const { height: th } = useElementSize(
  ref(document.getElementById('_zt-head-container'))
)
const { height: tf } = useElementSize(
  ref(document.getElementById('_ztf-container'))
)

// state
const { VITE_QINQING_WEB: qq } = import.meta.env
const prefetchs: PrefetchItem[] = [
  {
    label: '亲清企服PC',
    key: 'iframe',
    prefetch: false,
    url: String(qq).startsWith('http') ? qq : App.domain + qq,
    origin: App.qinqing
  },
  { label: '文件系统', key: 'meta-files', prefetch: false }
]
usePrefetch({ items: prefetchs })

// control
onMounted(() => {
  setMainMinHeight()
})
watch(height, () => setMainMinHeight())

// fns
function setMainMinHeight () {
  const main: any = document.getElementById('app')
  main.style.minHeight = `${
    height.value - (th.value || 100) - (tf.value || 156)
  }px`
}
</script>
