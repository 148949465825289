<template>
  <ElDialog
    v-model="visible"
    title="滨小办智能助手（内测版）"
    append-to-body
    :show-close="false"
    width="1300px"
    top="5vh"
    class="chat-dialog"
    modal-class="chat-modal"
  >
    <section class="chat-container w100% min-h-640PX h75vh">
      <iframe
        width="100%"
        height="100%"
        :src="url"
        frameborder="0"
      ></iframe>
    </section>

    <section class="close-btn" @click="onClose">
      <img class="w25 h25" src="@static/images/chat/close.png" alt="close">
    </section>
  </ElDialog>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import useSubscribe from '@/hooks/events/use-subscribe'
import { onlineQA } from '@/global/pubsub'

const { VITE_ENV, VITE_DOMAIN } = import.meta.env
const uDomain = VITE_ENV === 'development' ? 'https://ysx.com.cn/yuhangplatform' : VITE_DOMAIN
const visible = defineModel<boolean>({ required: true })
const url = ref(`${uDomain}/ysx-chat/index.html`)
// const url = ref('http://192.168.0.223:9300/')

// fns
function onOpen () {
  visible.value = true
}

function onClose () {
  visible.value = false
}

// subscribe
useSubscribe(onlineQA.OPEN).subscribe((opts: any) => {
  onOpen()
})
</script>
<style lang="scss">
.chat-modal {
  position: relative;
  .el-overlay-dialog {
    overflow: unset;
  }
  .close-btn {
    position: absolute;
    top: 6px;
    right: 12px;
    z-index: 1;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
      filter: sepia(1);
    }
  }
}
div.chat-dialog {
  border-radius: 10px;
  padding: 0;
  .chat-container {
    width: 100%;
    height: 75vh;
    min-height: 640px;
  }
  i.el-dialog__close {
    font-size: 18px;
    color: $primary-color !important;
  }
  .el-dialog__header {
    display: none;
    .el-dialog__headerbtn {
      top: 2px;
      width: 26px;
      height: 26px;
    }
    .el-dialog__title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      font-family: PingFang SC;
      color: #333;
      display: none;
      &::before {
        content: '';
        width: 4px;
        height: 18px;
        background: #2768e4;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }
  .el-dialog__body {
    padding: 12px 8px;
    padding: 0;
  }
  .el-dialog__footer {
    border-top: 1px solid #d3dddf;
    padding-bottom: 12px;
    .dialog-footer {
      text-align: center;
    }
  }
  .footer {
    border-top: 1px solid #d3dddf;
    padding-top: 15px;
    .operate {
      text-align: center;
    }
  }
}
.chat-icon-box {
  position: fixed;
  z-index: 1000;
  .onlineqa-btn {
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: 1;
    background: transparent;
    width: 100%;
    height: 40px;
  }
  .suqiu-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 1;
    background: transparent;
    width: 100%;
    height: 40px;
  }
}
</style>
